export var LOGO_MAX_WIDTH = 92;
export var LOGO_HEIGHT = 40;
export var SEARCH_WIDTHS = ['100%', 300, 300, 362];
export var CONTAINER_PADDING_X = ['sp_16', 'sp_24', 'sp_48', 'sp_64'];
export var CONTAINER_PADDING_Y = ['sp_16', null, null, 'sp_24'];
export var PADDING_OFFSET = 120;
export var CONTAINER_MAX_WIDTH = 1600;
export var FIXED_NAV_Z_INDEX = 1000;
export var FIXED_SEARCH_Z_INDEX = 1499;
export var MOBILE_VIEW_SCROLL_OFFSET = 110;
export var MINIMAL_SEARCH_CONTAINER_SIZE = 42;
export var SCROLL_DIRECTIONS = {
    UP: 'UP',
    DOWN: 'DOWN',
};
