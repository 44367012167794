import { CONTAINER_MAX_WIDTH, LOGO_MAX_WIDTH, PADDING_OFFSET, SEARCH_WIDTHS } from './constants';
export var getTopBarNavigation = function (navigation) {
    var _a;
    return (_a = navigation.children) === null || _a === void 0 ? void 0 : _a.reduce(function (topNavigation, _a) {
        var name = _a.name, href = _a.href;
        if (name) {
            topNavigation.push({ name: name, href: href });
        }
        return topNavigation;
    }, []);
};
export var getMenuWidths = function () {
    return SEARCH_WIDTHS.slice(1).map(function (searchWidth) {
        return "calc(min(100vw, ".concat(CONTAINER_MAX_WIDTH, "px) - ").concat(Number(searchWidth) + LOGO_MAX_WIDTH + PADDING_OFFSET, "px)");
    });
};
export var getScrollTop = function (scrollable) {
    if (scrollable instanceof Window) {
        return scrollable.scrollY;
    }
    if (scrollable instanceof HTMLElement) {
        return scrollable.scrollTop;
    }
    return 0;
};
// This is a simplified version of the code from the react-waypoint library
// that helps to find the first scrollable ancestor of a DOM node
//
// https://github.com/brigade/react-waypoint/blob/6004756ad6b6699f358fc6008e29e242b2777379/src/waypoint.jsx#L134-L168
export var findScrollableAncestor = function (node) {
    while (node && node.parentNode) {
        node = node.parentNode;
        if (node === document.body) {
            return window;
        }
        var style = window.getComputedStyle(node);
        var overflow = style.getPropertyValue('overflow-y') || style.getPropertyValue('overflow');
        if (overflow === 'auto' || overflow === 'scroll') {
            return node;
        }
    }
    return window;
};
