import { useEffect, useRef } from 'react';
import { MOBILE_VIEW_SCROLL_OFFSET, SCROLL_DIRECTIONS } from '../constants';
import { findScrollableAncestor, getScrollTop } from '../utils';
var LISTENING_EVENTS = ['scroll', 'resize'];
var useOnScrollChange = function (_a) {
    var onDirectionChange = _a.onDirectionChange, onContainerReached = _a.onContainerReached, placeholderRef = _a.placeholderRef;
    var prevScrollDirection = useRef(null);
    var prevScrollTop = useRef(0);
    var dirChangePos = useRef(null);
    var animationRefMap = {
        scroll: useRef(null),
        resize: useRef(null),
    };
    useEffect(function () {
        var scrollable = findScrollableAncestor(placeholderRef.current);
        prevScrollTop.current = getScrollTop(scrollable);
        var listener = function (eventType) { return function () {
            var animationId = animationRefMap[eventType].current;
            if (animationId) {
                cancelAnimationFrame(animationId);
            }
            animationRefMap[eventType].current = requestAnimationFrame(function () {
                var _a;
                var scrollTop = getScrollTop(scrollable);
                var currentDirection = scrollTop < prevScrollTop.current
                    ? SCROLL_DIRECTIONS.UP
                    : scrollTop > prevScrollTop.current
                        ? SCROLL_DIRECTIONS.DOWN
                        : prevScrollDirection.current;
                onContainerReached(window.scrollY > (((_a = placeholderRef === null || placeholderRef === void 0 ? void 0 : placeholderRef.current) === null || _a === void 0 ? void 0 : _a.offsetTop) || 0));
                if (currentDirection !== prevScrollDirection.current) {
                    dirChangePos.current = scrollTop;
                }
                if (Math.abs(scrollTop - (dirChangePos.current || 0)) > MOBILE_VIEW_SCROLL_OFFSET) {
                    onDirectionChange(currentDirection);
                }
                prevScrollDirection.current = currentDirection;
                prevScrollTop.current = scrollTop;
            });
        }; };
        var handlersMap = {};
        LISTENING_EVENTS.forEach(function (eventType) {
            handlersMap[eventType] = listener(eventType);
            scrollable.addEventListener(eventType, handlersMap[eventType], { passive: true });
        });
        return function () {
            LISTENING_EVENTS.forEach(function (eventType) {
                scrollable.removeEventListener(eventType, handlersMap[eventType]);
                var animationId = animationRefMap[eventType].current;
                if (animationId) {
                    cancelAnimationFrame(animationId);
                }
            });
        };
    }, [onDirectionChange, onContainerReached, placeholderRef]);
};
export default useOnScrollChange;
