var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { borderColor, borderRadius, borderStyle, borderWidth, minWidth, paddingRight, position, right, size, space, top, width, zIndex, } from 'styled-system';
import PrismicResponsiveImage from '../../common/components/PrismicResponsiveImage';
import Search from '../../common/components/Search/Search';
import { transformToPrismicImage } from '../../common/utils/image';
import { SideNavType } from '../../common/utils/navigation';
import SideNav from '../../side-nav/components/SideNav';
import { CONTAINER_MAX_WIDTH, CONTAINER_PADDING_X, CONTAINER_PADDING_Y, FIXED_SEARCH_Z_INDEX, SEARCH_WIDTHS, } from './constants';
import MenuWithLogo from './MenuWithLogo';
import ScrollContainer from './ScrollContainer';
import { Container, getContainerStyles, getGridStyles, getMinimalSearchStyles, Grid, } from './styles';
var SearchContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n\n  ", "\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  ", "\n\n  ", "\n"])), paddingRight, minWidth, space, position, zIndex, top, right, borderRadius, borderColor, borderWidth, borderStyle, size, function (props) {
    return props.navigationOptions.isMobileStickyMinimal &&
        props.withAnimation && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      @keyframes shrinkContainerWidth {\n        from {\n          min-width: 100%;\n        }\n        to {\n          min-width: fit-content;\n        }\n      }\n      animation: shrinkContainerWidth 0.8s ease-in-out forwards;\n    "], ["\n      @keyframes shrinkContainerWidth {\n        from {\n          min-width: 100%;\n        }\n        to {\n          min-width: fit-content;\n        }\n      }\n      animation: shrinkContainerWidth 0.8s ease-in-out forwards;\n    "])));
}, function (props) {
    return props.navigationOptions.isMobileStickyExpanded &&
        props.withAnimation && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      @keyframes expandContainerWidth {\n        from {\n          min-width: 24px;\n          width: 24px;\n        }\n        to {\n          min-width: 100%;\n        }\n      }\n      animation: expandContainerWidth 0.3s ease-in-out forwards;\n    "], ["\n      @keyframes expandContainerWidth {\n        from {\n          min-width: 24px;\n          width: 24px;\n        }\n        to {\n          min-width: 100%;\n        }\n      }\n      animation: expandContainerWidth 0.3s ease-in-out forwards;\n    "])));
});
var fixedSearchContainerStyles = {
    py: CONTAINER_PADDING_Y,
    paddingRight: CONTAINER_PADDING_X,
    minWidth: SEARCH_WIDTHS,
    position: 'fixed',
    zIndex: FIXED_SEARCH_Z_INDEX,
    top: 0,
    right: 0,
    mr: "max(0px, calc((100% - ".concat(CONTAINER_MAX_WIDTH, "px) / 2))"),
};
var getSearchContainerStyles = function (withAnimation, isMobile) { return (__assign({ minWidth: SEARCH_WIDTHS }, getMinimalSearchStyles(withAnimation, isMobile))); };
var Banner = styled(PrismicResponsiveImage)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), width);
var bannerStyles = {
    width: 1,
};
var ShopInShopNavigation = function (_a) {
    var _b;
    var searchParams = _a.searchParams, navigation = _a.navigation, filterQuery = _a.filterQuery, shopName = _a.shopName, homepage = _a.homepage, banner = _a.banner, logo = _a.logo, _c = _a.withAnimation, withAnimation = _c === void 0 ? true : _c;
    var shopUrl = (_b = homepage === null || homepage === void 0 ? void 0 : homepage.fullUrl) !== null && _b !== void 0 ? _b : undefined;
    var _d = __read(useState({
        isMobileStickyExpanded: false,
        isMobileStickyMinimal: false,
        isNonMobileSticky: false,
        isMobile: false,
        height: 0,
    }), 2), navigationOptions = _d[0], setNavigationOptions = _d[1];
    var isNonMobileSticky = navigationOptions.isNonMobileSticky, isMobileStickyMinimal = navigationOptions.isMobileStickyMinimal, navHeight = navigationOptions.height, isMobile = navigationOptions.isMobile;
    var containerStyles = getContainerStyles(isNonMobileSticky);
    var isMinimalHeader = isMobileStickyMinimal || (!withAnimation && isMobile);
    return (React.createElement(Container, __assign({}, containerStyles),
        React.createElement(SideNav, { navigation: navigation, logoUrl: logo.url, shopName: shopName, shopUrl: shopUrl, withBottomSection: false, type: SideNavType.ShopInShop }),
        banner && React.createElement(Banner, __assign({}, bannerStyles, { image: transformToPrismicImage(banner) })),
        React.createElement(ScrollContainer, { withAnimation: withAnimation, setNavigationOptions: setNavigationOptions },
            React.createElement(Grid, __assign({}, getGridStyles(isNonMobileSticky, navHeight)),
                React.createElement(MenuWithLogo, { shopName: shopName, logo: logo, navigation: navigation, isMinimalHeader: isMinimalHeader, shopUrl: shopUrl, isNonMobileSticky: isNonMobileSticky }),
                filterQuery && (React.createElement(SearchContainer, __assign({ navigationOptions: navigationOptions, withAnimation: withAnimation, isMobile: isMobile }, (isNonMobileSticky
                    ? fixedSearchContainerStyles
                    : getSearchContainerStyles(withAnimation, isMobile))),
                    React.createElement(Search, { params: searchParams, withRecentSearches: false, withProductSuggestions: false, iconVariant: "secondary", filterQuery: filterQuery, shopName: shopName, showOnlySearchIcon: isMinimalHeader, withAnimation: withAnimation, minimalIcon: isMinimalHeader, overlayStacking: FIXED_SEARCH_Z_INDEX })))))));
};
export default ShopInShopNavigation;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
