var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { alignItems, borderColor, borderStyle, borderWidth, display, flexWrap, height, justifyContent, maxWidth, space, } from 'styled-system';
import { borderBottomWidth, borderTopWidth, boxSizing } from '../../common/theme/system-utilities';
import { CONTAINER_MAX_WIDTH, CONTAINER_PADDING_X, CONTAINER_PADDING_Y, MINIMAL_SEARCH_CONTAINER_SIZE, } from './constants';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, borderWidth, borderTopWidth, borderBottomWidth, borderColor, borderStyle);
export var getContainerStyles = function (isSticky) { return ({
    mx: 'auto',
    borderWidth: 0,
    borderTopWidth: isSticky ? 0 : 'small',
    borderBottomWidth: isSticky ? 0 : 'small',
    borderColor: 'sys.neutral.border.weak',
    borderStyle: 'solid',
}); };
export var Grid = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), flexWrap, display, alignItems, justifyContent, maxWidth, space, boxSizing, height);
export var getGridStyles = function (isNonMobileSticky, navHeight) { return ({
    flexWrap: ['wrap', 'nowrap'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: CONTAINER_PADDING_Y,
    px: CONTAINER_PADDING_X,
    maxWidth: CONTAINER_MAX_WIDTH,
    boxSizing: 'border-box',
    mx: 'auto',
    height: isNonMobileSticky ? navHeight : '',
}); };
export var getMinimalSearchStyles = function (withAnimation, isMobile) {
    return !withAnimation && isMobile
        ? {
            minWidth: 'fit-content',
            borderRadius: 'circle',
            borderColor: 'sys.neutral.border.default',
            borderWidth: 'small',
            borderStyle: 'solid',
            size: MINIMAL_SEARCH_CONTAINER_SIZE,
        }
        : {};
};
var templateObject_1, templateObject_2;
