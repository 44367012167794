var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import styled from '@emotion/styled';
import { alignItems, borderColor, borderWidth, color, display, height, justifyContent, left, marginRight, maxWidth, paddingLeft, position, space, top, width, zIndex, } from 'styled-system';
import { borderBottomStyle, flexGrow, objectFit } from '../../common/theme/system-utilities';
import { NavigationParent } from '../../common/types/tracking';
import { SideNavType } from '../../common/utils/navigation';
import { trackLogoClick } from '../../global-header/components/toolbar/tracking';
import TopNavigation from '../../global-header/components/top-navigation/TopNavigation';
import { CONTAINER_MAX_WIDTH, CONTAINER_PADDING_X, CONTAINER_PADDING_Y, FIXED_NAV_Z_INDEX, LOGO_HEIGHT, LOGO_MAX_WIDTH, } from './constants';
import { getMenuWidths, getTopBarNavigation } from './utils';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), width, display, alignItems, justifyContent, flexGrow);
var getContainerStyles = function (isMinimalHeader) { return ({
    width: [isMinimalHeader ? 'fit-content' : '100%', 'fit-content'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: [isMinimalHeader ? 'flex-start' : 'space-between', 'flex-start'],
    flexGrow: 1,
}); };
var FixedMenuContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, position, paddingLeft, top, left, width, color, zIndex, borderBottomStyle, borderWidth, borderColor);
var fixedMenuContainerStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    py: CONTAINER_PADDING_Y,
    paddingLeft: CONTAINER_PADDING_X,
    bg: 'sys.neutral.background.default',
    width: '100%',
    zIndex: FIXED_NAV_Z_INDEX,
    borderBottomStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.weak',
};
var FixedMenu = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
var fixedMenuStyles = {
    ml: "max(0px, calc((100% - ".concat(CONTAINER_MAX_WIDTH, "px) / 2))"),
};
var LogoWrapper = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), marginRight);
var logoWrapperStyles = {
    mr: ['sp_16', 'sp_24', 'sp_0'],
};
var Logo = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), maxWidth, width, height, objectFit, marginRight);
var logoStyles = {
    maxWidth: LOGO_MAX_WIDTH,
    width: 'auto',
    height: LOGO_HEIGHT,
    objectFit: 'contain',
    mr: ['sp_16', 'sp_24', 'sp_0'],
};
var NavigationBarContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), maxWidth, width);
var navigationBarContainerStyles = {
    maxWidth: __spreadArray(['fit-content'], __read(getMenuWidths()), false),
    width: __spreadArray(['fit-content'], __read(getMenuWidths()), false),
};
var MenuWithLogo = function (_a) {
    var logo = _a.logo, navigation = _a.navigation, shopName = _a.shopName, shopUrl = _a.shopUrl, _b = _a.isMinimalHeader, isMinimalHeader = _b === void 0 ? false : _b, _c = _a.isNonMobileSticky, isNonMobileSticky = _c === void 0 ? false : _c;
    var containerStyles = getContainerStyles(isMinimalHeader);
    var logoWrapperProps = shopUrl
        ? {
            href: shopUrl,
            onClick: function () {
                trackLogoClick(NavigationParent.ShopInShopNav, shopName);
            },
        }
        : { as: 'div' };
    return (React.createElement(FixedMenuContainer, __assign({}, (isNonMobileSticky ? fixedMenuContainerStyles : containerStyles)),
        React.createElement(FixedMenu, __assign({}, (isNonMobileSticky && fixedMenuStyles)),
            React.createElement(Container, __assign({}, containerStyles),
                React.createElement(LogoWrapper, __assign({}, logoWrapperStyles, logoWrapperProps),
                    React.createElement(Logo, __assign({}, logoStyles, { src: logo.url, alt: shopName || logo.alt || '' }))),
                React.createElement(NavigationBarContainer, __assign({}, navigationBarContainerStyles),
                    React.createElement(TopNavigation, { navigation: getTopBarNavigation(navigation), shopName: shopName, sideNavType: SideNavType.ShopInShop, withBoxShadow: false, withArrows: true, wrapItems: true, asButtonOnMobile: true }))))));
};
export default MenuWithLogo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
