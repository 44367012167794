var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { height, themeGet, width } from 'styled-system';
import { useMatchesViewport } from '../../common/hooks/media';
import { visibility } from '../../common/theme/system-utilities';
import { FIXED_NAV_Z_INDEX, SCROLL_DIRECTIONS } from './constants';
import useOnScrollChange from './hooks/useOnScrollChange';
var StickyContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (props) {
    return props.sticky &&
        "\n          position: fixed;\n          min-width: 100%;\n          top: 0;\n          border: ".concat(themeGet('borderWidths.small')(props), " solid ").concat(themeGet('colors.sys.neutral.border.weak')(props), ";\n          z-index: ").concat(FIXED_NAV_Z_INDEX, ";\n          background: #ffffff;\n          right: 0;\n        ");
});
var PlaceholderContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n"])), visibility, height, width);
var getPlaceholderStyles = function (isSticky, containerHeight) { return ({
    visibility: 'hidden',
    height: isSticky ? containerHeight : 0,
    width: 0,
}); };
var ScrollContainer = function (_a) {
    var _b;
    var children = _a.children, setNavigationOptions = _a.setNavigationOptions, withAnimation = _a.withAnimation;
    var placeholderRef = useRef(null);
    var stickContainerRef = useRef(null);
    var _c = __read(useState(null), 2), scrollDirection = _c[0], setScrollDirection = _c[1];
    var _d = __read(useState(false), 2), isSticky = _d[0], setIsSticky = _d[1];
    useOnScrollChange({
        onDirectionChange: setScrollDirection,
        onContainerReached: setIsSticky,
        placeholderRef: placeholderRef,
    });
    var matchesTablet = useMatchesViewport().matches.matchesTablet;
    var isMobileFixed = isSticky && !matchesTablet;
    useEffect(function () {
        var _a;
        var isMobileStickyExpanded = isMobileFixed && scrollDirection === SCROLL_DIRECTIONS.UP;
        var isMobileStickyMinimal = isMobileFixed && scrollDirection === SCROLL_DIRECTIONS.DOWN;
        if (!withAnimation) {
            isMobileStickyExpanded = false;
            isMobileStickyMinimal = isMobileFixed;
        }
        setNavigationOptions({
            isMobileStickyExpanded: isMobileStickyExpanded,
            isMobileStickyMinimal: isMobileStickyMinimal,
            isNonMobileSticky: isSticky && matchesTablet,
            isMobile: !matchesTablet,
            height: ((_a = stickContainerRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0,
        });
    }, [scrollDirection, isSticky, matchesTablet, isMobileFixed]);
    var containerHeight = isMobileFixed
        ? ((_b = stickContainerRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) || 0
        : 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(PlaceholderContainer, __assign({}, getPlaceholderStyles(isSticky, containerHeight), { ref: placeholderRef })),
        React.createElement(StickyContainer, { sticky: isMobileFixed, ref: stickContainerRef }, children)));
};
export default ScrollContainer;
var templateObject_1, templateObject_2;
